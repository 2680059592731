<template>
  <div class="affiliate-vehicle-sidebar">
    <div class="affiliate-vehicle-sidebar--content">
      <v-form ref="form">
        <div
          class="px-4 py-3"
          :style="`background-color: ${$cr.theme.blueLight}`"
        >
          <div style="font-size: 12px">
            <b>ELD Type:</b>
            {{ eldType }}
          </div>

          <div style="font-size: 12px; margin-top: 10px">
            <b>Device ID:</b>
            {{ deviceId }}
          </div>

          <div style="font-size: 12px; margin-top: 10px">
            <b>VIN:</b>
            {{ vinNumber || 'N/A' }}
          </div>

          <div style="font-size: 12px; margin-top: 10px">
            <b>Rating:</b>
            {{ trackingVehicle.rating }}/5
          </div>
        </div>
        <div>
          <AffiliatesSummaryTrackingMap
            id="vehicleSidebarMap"
            ref="vehicleSidebarMap"
            :filtered-vehicles="vehicleLocations"
            :refresh-interval="standardRefreshInterval"
          />
          <br />
          <div v-if="todaysReservations.length === 0">
            <v-flex class="mb-2" style="font-size: 12px" xs12>
              <b>Last Reservation:</b>
              <router-link
                v-if="trackingVehicle.lastReservation"
                :id="`reservation-link-${trackingVehicle.lastReservation}`"
                target="_blank"
                :to="{
                  name: 'reservation-detail',
                  params: {
                    id:
                      trackingVehicle.lastReservationParentId ||
                      trackingVehicle.lastReservation,
                  },
                }"
                class="reservation-link"
              >
                {{ trackingVehicle.lastManagedId }}
              </router-link>
              <span v-else>N/A</span>
            </v-flex>
            <v-flex class="mb-2" style="font-size: 12px" xs12>
              <b>Last Report Time:</b>
              <span v-if="trackingVehicle.lastSyncDate">
                {{ isoToString(trackingVehicle.lastSyncDate) }}
              </span>
              <span v-else>N/A</span>
            </v-flex>
            <v-flex style="font-size: 12px" xs12>
              <b>License #:</b>
              {{ licensePlate }}
            </v-flex>
          </div>
          <div v-else-if="todaysReservations.length > 0">
            <h6>Today's Reservations</h6>
            <br />
            <v-layout row wrap>
              <v-flex
                v-for="(todaysReservation,
                todaysReservationIndex) in todaysReservations"
                :id="`vehicle-${todaysReservation}-${todaysReservationIndex}`"
                :key="`vehicle-${todaysReservation}-${todaysReservationIndex}`"
              >
                <v-layout>
                  <v-flex xs1>
                    <h3 :style="`color: ${$cr.theme.lightGray}`">
                      {{ todaysReservationIndex + 1 }}
                    </h3>
                  </v-flex>
                  <v-flex xs11>
                    <b>{{ `Reservation: ` }}</b>
                    <a
                      target="_blank"
                      style="text-decoration: none"
                      :href="
                        $router.resolve({
                          name: 'reservation-detail',
                          params: {
                            id: todaysReservation.parentReservationId || 0,
                          },
                        }).href
                      "
                    >
                      <b>#{{ todaysReservation.managedId }}</b>
                    </a>
                    <br class="mb-2" />
                    <b>{{ `Trip Dates: ` }}</b>
                    {{
                      formatDateRangeString(
                        todaysReservation.startDate,
                        todaysReservation.endDate,
                        todaysReservation
                      )
                    }}
                    <br class="mb-2" />
                    <v-timeline align-top dense left class="trip-itinerary">
                      <v-timeline-item
                        v-for="(stop, index) in todaysReservation.trip.stops"
                        :key="stop.id"
                        color="$gray-mid-light"
                        small
                        fill-dot
                      >
                        <div>
                          <b>Stop #{{ index + 1 }}</b>
                          {{ stopName(stop) }}
                        </div>
                      </v-timeline-item>
                    </v-timeline>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </div>
        </div>
        <br />
        <v-flex>
          <v-divider />
          <h6>Upcoming Reservations</h6>
          <br />
        </v-flex>
        <v-layout row wrap>
          <div v-if="upcomingReservations.length === 0">
            There are no upcoming reservations.
            <br />
          </div>
          <v-flex
            v-for="(upcomingReservation,
            upcomingReservationIndex) in upcomingReservations"
            :id="`vehicle-${upcomingReservation}-${upcomingReservationIndex}`"
            :key="`vehicle-${upcomingReservation}-${upcomingReservationIndex}`"
          >
            <v-layout>
              <v-flex xs1>
                <h3 :style="`color: ${$cr.theme.lightGray}`">
                  {{ upcomingReservationIndex + 1 }}
                </h3>
              </v-flex>
              <v-flex xs11>
                <b>{{ `Reservation: ` }}</b>
                <a
                  target="_blank"
                  style="text-decoration: none"
                  :href="
                    $router.resolve({
                      name: 'reservation-detail',
                      params: {
                        id:
                          upcomingReservation.parentReservationId ||
                          upcomingReservation.reservationId,
                      },
                    }).href
                  "
                >
                  <b>#{{ upcomingReservation.managedId }}</b>
                </a>
                <br class="mb-2" />
                <b>{{ `Trip Dates: ` }}</b>
                {{
                  formatDateRangeString(
                    upcomingReservation.startDate,
                    upcomingReservation.endDate,
                    upcomingReservation
                  )
                }}
                <br class="mb-2" />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <br />
        <v-flex>
          <v-divider />
          <h6>Upcoming Unavailability</h6>
          <br />
        </v-flex>
        <v-layout row wrap>
          <div v-if="upcomingUnavailability.length === 0">
            There are no upcoming Unavailabilities.
            <br />
          </div>
          <v-flex
            v-for="(upcomingUnavail,
            upcomingUnavailabilityIndex) in upcomingUnavailability"
            :id="`vehicle-${upcomingUnavail}-${upcomingUnavailabilityIndex}`"
            :key="`vehicle-${upcomingUnavail}-${upcomingUnavailabilityIndex}`"
          >
            <v-layout>
              <v-flex xs11>
                {{
                  formatDateRangeString(
                    upcomingUnavail.startDatetime,
                    upcomingUnavail.endDatetime,
                    null,
                    true
                  )
                }}
                &#8226; Marked By {{ upcomingUnavail.updatedByName }}
                <br class="mb-2" />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <br />
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AffiliatesSummaryTrackingMap from '@/components/AffiliatesSummaryTrackingMap.vue'
import { isoToString, friendlyDate } from '@/utils/time'

export default {
  components: {
    AffiliatesSummaryTrackingMap,
  },
  props: {
    vehicleId: {
      type: Number,
      default: null,
    },
    vehicleName: {
      type: String,
      default: null,
    },
    vinNumber: {
      type: String,
      default: null,
    },
    companyId: {
      type: Number,
      default: null,
    },
    deviceInfo: {
      type: String,
      default: null,
    },
    licensePlate: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      upcomingReservations: [],
      upcomingUnavailability: [],
      todaysReservations: [],
      standardRefreshInterval: 30,
      trackingVehicle: {},
    }
  },
  computed: {
    eldType() {
      const eldType = this.trackingVehicle.eldType
      return eldType
        ? `${eldType.charAt(0)}${eldType.slice(1)?.toLowerCase()}`
        : 'N/A'
    },
    deviceId() {
      return this.trackingVehicle.deviceId || 'N/A'
    },
    vehicleLocations() {
      const vehicleLocations = []
      if (this.trackingVehicle) {
        const lat = parseFloat(this.trackingVehicle.lat)
        const lng = parseFloat(this.trackingVehicle.lng)
        const vehicleLocation = {
          vehicleId: this.vehicleId,
          vehicleName: this.vehicleName,
          lat: lat,
          lng: lng,
          isInProgress: this.isVehicleInProgress,
          markerLabel: 1,
        }
        vehicleLocations.push(vehicleLocation)
      }
      return vehicleLocations
    },
    isVehicleInProgress() {
      return this.trackingVehicle.lastReservationStatus === 'started'
    },
  },
  async mounted() {
    await this.getTrackingVehicleAndReservations()
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    isoToString,
    friendlyDate,
    stopName(stop) {
      return this.addressPretty(stop) || stop.address.addressName
    },
    addressPretty(stop) {
      const street1 = `${stop?.address?.street1?.trim()} ` || ''
      const city = stop?.address?.city ? `${stop?.address?.city}, ` : ''
      const state = `${stop.address.state} ` || ''
      const postalCode = stop.address.postalCode || ''
      return `${street1}${city}${state}${postalCode}`
    },
    async getTrackingVehicleAndReservations() {
      if (!this.vehicleId) {
        return
      }
      const res = await this.$store.dispatch(
        'vehicles/getAffiliatesVehicleDetails',
        this.vehicleId
      )
      this.trackingVehicle = res.data.vehicle
      this.upcomingReservations = res.data.upcomingReservations
      this.todaysReservations = res.data.todaysReservations
      this.upcomingUnavailability = res.data.upcomingUnavailability
    },
    formatDateRangeString(
      startDate,
      endDate,
      reservation = null,
      dateOnly = false
    ) {
      const tz = reservation?.trip?.stops[0]?.address?.timeZone
      if (!dateOnly) {
        return isoToString(startDate, tz) + ' - ' + isoToString(endDate, tz)
      } else {
        return friendlyDate(startDate) + ' - ' + friendlyDate(endDate)
      }
    },
    close() {
      this.$store.dispatch('app/closeDialog')
    },
  },
}
</script>
<style lang="scss" scoped>
.affiliate-vehicle-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 20px 40px;
  }
}

.trip-itinerary {
  margin-top: 10px;
}

::v-deep .v-timeline-item__body {
  margin-top: 6px;
  font-family: 'Airbnb Cereal App Medium', 'Lato', sans-serif;
  font-weight: 400;
  font-size: 12px;
  padding-left: 0;

  h4 {
    font-size: 12px;
    line-height: 1;
    font-weight: 600;
  }
}

::v-deep .v-timeline-item__dot {
  height: 10px;
  width: 10px;
  background-color: $gray-mid-light !important;
  margin-left: 7px;
  z-index: 10;
  box-shadow: none;

  .v-timeline-item__inner-dot {
    height: 8px;
    margin: 2px;
    width: 8px;
  }
}

::v-deep .vue-map-container {
  height: 300px;
}
</style>
