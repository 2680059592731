var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"affiliate-vehicle-sidebar"},[_c('div',{staticClass:"affiliate-vehicle-sidebar--content"},[_c('v-form',{ref:"form"},[_c('div',{staticClass:"px-4 py-3",style:(`background-color: ${_vm.$cr.theme.blueLight}`)},[_c('div',{staticStyle:{"font-size":"12px"}},[_c('b',[_vm._v("ELD Type:")]),_vm._v(" "+_vm._s(_vm.eldType)+" ")]),_c('div',{staticStyle:{"font-size":"12px","margin-top":"10px"}},[_c('b',[_vm._v("Device ID:")]),_vm._v(" "+_vm._s(_vm.deviceId)+" ")]),_c('div',{staticStyle:{"font-size":"12px","margin-top":"10px"}},[_c('b',[_vm._v("VIN:")]),_vm._v(" "+_vm._s(_vm.vinNumber || 'N/A')+" ")]),_c('div',{staticStyle:{"font-size":"12px","margin-top":"10px"}},[_c('b',[_vm._v("Rating:")]),_vm._v(" "+_vm._s(_vm.trackingVehicle.rating)+"/5 ")])]),_c('div',[_c('AffiliatesSummaryTrackingMap',{ref:"vehicleSidebarMap",attrs:{"id":"vehicleSidebarMap","filtered-vehicles":_vm.vehicleLocations,"refresh-interval":_vm.standardRefreshInterval}}),_c('br'),(_vm.todaysReservations.length === 0)?_c('div',[_c('v-flex',{staticClass:"mb-2",staticStyle:{"font-size":"12px"},attrs:{"xs12":""}},[_c('b',[_vm._v("Last Reservation:")]),(_vm.trackingVehicle.lastReservation)?_c('router-link',{staticClass:"reservation-link",attrs:{"id":`reservation-link-${_vm.trackingVehicle.lastReservation}`,"target":"_blank","to":{
                name: 'reservation-detail',
                params: {
                  id:
                    _vm.trackingVehicle.lastReservationParentId ||
                    _vm.trackingVehicle.lastReservation,
                },
              }}},[_vm._v(" "+_vm._s(_vm.trackingVehicle.lastManagedId)+" ")]):_c('span',[_vm._v("N/A")])],1),_c('v-flex',{staticClass:"mb-2",staticStyle:{"font-size":"12px"},attrs:{"xs12":""}},[_c('b',[_vm._v("Last Report Time:")]),(_vm.trackingVehicle.lastSyncDate)?_c('span',[_vm._v(" "+_vm._s(_vm.isoToString(_vm.trackingVehicle.lastSyncDate))+" ")]):_c('span',[_vm._v("N/A")])]),_c('v-flex',{staticStyle:{"font-size":"12px"},attrs:{"xs12":""}},[_c('b',[_vm._v("License #:")]),_vm._v(" "+_vm._s(_vm.licensePlate)+" ")])],1):(_vm.todaysReservations.length > 0)?_c('div',[_c('h6',[_vm._v("Today's Reservations")]),_c('br'),_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.todaysReservations),function(todaysReservation,todaysReservationIndex){return _c('v-flex',{key:`vehicle-${todaysReservation}-${todaysReservationIndex}`,attrs:{"id":`vehicle-${todaysReservation}-${todaysReservationIndex}`}},[_c('v-layout',[_c('v-flex',{attrs:{"xs1":""}},[_c('h3',{style:(`color: ${_vm.$cr.theme.lightGray}`)},[_vm._v(" "+_vm._s(todaysReservationIndex + 1)+" ")])]),_c('v-flex',{attrs:{"xs11":""}},[_c('b',[_vm._v(_vm._s(`Reservation: `))]),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"target":"_blank","href":_vm.$router.resolve({
                        name: 'reservation-detail',
                        params: {
                          id: todaysReservation.parentReservationId || 0,
                        },
                      }).href}},[_c('b',[_vm._v("#"+_vm._s(todaysReservation.managedId))])]),_c('br',{staticClass:"mb-2"}),_c('b',[_vm._v(_vm._s(`Trip Dates: `))]),_vm._v(" "+_vm._s(_vm.formatDateRangeString( todaysReservation.startDate, todaysReservation.endDate, todaysReservation ))+" "),_c('br',{staticClass:"mb-2"}),_c('v-timeline',{staticClass:"trip-itinerary",attrs:{"align-top":"","dense":"","left":""}},_vm._l((todaysReservation.trip.stops),function(stop,index){return _c('v-timeline-item',{key:stop.id,attrs:{"color":"$gray-mid-light","small":"","fill-dot":""}},[_c('div',[_c('b',[_vm._v("Stop #"+_vm._s(index + 1))]),_vm._v(" "+_vm._s(_vm.stopName(stop))+" ")])])}),1)],1)],1)],1)}),1)],1):_vm._e()],1),_c('br'),_c('v-flex',[_c('v-divider'),_c('h6',[_vm._v("Upcoming Reservations")]),_c('br')],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[(_vm.upcomingReservations.length === 0)?_c('div',[_vm._v(" There are no upcoming reservations. "),_c('br')]):_vm._e(),_vm._l((_vm.upcomingReservations),function(upcomingReservation,upcomingReservationIndex){return _c('v-flex',{key:`vehicle-${upcomingReservation}-${upcomingReservationIndex}`,attrs:{"id":`vehicle-${upcomingReservation}-${upcomingReservationIndex}`}},[_c('v-layout',[_c('v-flex',{attrs:{"xs1":""}},[_c('h3',{style:(`color: ${_vm.$cr.theme.lightGray}`)},[_vm._v(" "+_vm._s(upcomingReservationIndex + 1)+" ")])]),_c('v-flex',{attrs:{"xs11":""}},[_c('b',[_vm._v(_vm._s(`Reservation: `))]),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"target":"_blank","href":_vm.$router.resolve({
                    name: 'reservation-detail',
                    params: {
                      id:
                        upcomingReservation.parentReservationId ||
                        upcomingReservation.reservationId,
                    },
                  }).href}},[_c('b',[_vm._v("#"+_vm._s(upcomingReservation.managedId))])]),_c('br',{staticClass:"mb-2"}),_c('b',[_vm._v(_vm._s(`Trip Dates: `))]),_vm._v(" "+_vm._s(_vm.formatDateRangeString( upcomingReservation.startDate, upcomingReservation.endDate, upcomingReservation ))+" "),_c('br',{staticClass:"mb-2"})])],1)],1)})],2),_c('br'),_c('v-flex',[_c('v-divider'),_c('h6',[_vm._v("Upcoming Unavailability")]),_c('br')],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[(_vm.upcomingUnavailability.length === 0)?_c('div',[_vm._v(" There are no upcoming Unavailabilities. "),_c('br')]):_vm._e(),_vm._l((_vm.upcomingUnavailability),function(upcomingUnavail,upcomingUnavailabilityIndex){return _c('v-flex',{key:`vehicle-${upcomingUnavail}-${upcomingUnavailabilityIndex}`,attrs:{"id":`vehicle-${upcomingUnavail}-${upcomingUnavailabilityIndex}`}},[_c('v-layout',[_c('v-flex',{attrs:{"xs11":""}},[_vm._v(" "+_vm._s(_vm.formatDateRangeString( upcomingUnavail.startDatetime, upcomingUnavail.endDatetime, null, true ))+" • Marked By "+_vm._s(upcomingUnavail.updatedByName)+" "),_c('br',{staticClass:"mb-2"})])],1)],1)})],2),_c('br')],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }